/** number input **/
.@{ant-prefix}-input-number {
  &-affix-wrapper {
    width: 100%;
  }
}
/** picker input **/
.@{ant-prefix}-picker {
  width: 100%;
}
/** uploader **/
.@{upload-prefix-cls} {
  &-picture-card-wrapper {
    width: auto;
  }
  &&-select-picture-card {
    background-color: @gray-5;
    background-color: #d9d9d9;
  }
}

/** menu item **/
.ant-menu-sub.ant-menu-inline,
.ant-menu-item-only-child {
  background-color: @layout-header-background;
}

.ant-menu-inline .ant-menu-item::after {
  content: none;
}
.ant-menu-item,
.ant-menu-submenu-title {
  border-left: 3px solid transparent;
}
.ant-menu-inline .ant-menu-item-selected {
  border-left: 3px solid @primary;
}
.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
  background: linear-gradient(90deg, #e6f7ff 0%, rgba(230, 247, 255, 0) 95.75%);
}

/** form item **/
.ant-form-item-extra,
.ant-form-item-explain-error {
  line-height: 20px;
  margin-top: 2px;
}

.@{segmented-prefix-cls} {
  border-radius: 24px;
  .ant-segmented-thumb,
  .ant-segmented,
  .ant-segmented-item-selected {
    border-radius: 24px;
  }
}
