@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.less';
@import './variables.less';
@import './overrides.less';

@font-face {
  font-family: 'SC Prosper Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@{static-folder}fonts/SCProsperSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SC Prosper Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('@{static-folder}fonts/SCProsperSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SC Prosper Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('@{static-folder}fonts/SCProsperSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'SC Prosper Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('@{static-folder}fonts/SCProsperSans-Bold.woff') format('woff');
}

@primary-color: @primary;
@error-color: @error;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'SC Prosper Sans';
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

svg {
  vertical-align: baseline;
}

ul[data-slate-node='element'] {
  list-style: initial;
  margin: initial;
  padding: 0 0 0 40px;
  list-style-position: inside;
}

ol[data-slate-node='element'] {
  list-style: initial;
  list-style-type: decimal;
  margin: initial;
  padding: 0 0 0 40px;
  list-style-position: inside;
}

li[data-slate-node='element'] {
  display: list-item;
}

h1[data-slate-node='element'] {
  font-weight: 600;
  font-size: 26px;
  line-height: 1.2105263157894737;
}

h2[data-slate-node='element'] {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2666666666666666;
}

ol,
ul {
  list-style-position: inside;
}

@static-folder: /;